import attendanceAPI from "../axios";
import { AnnouncementFormData } from "../../components/types";
 
export const createAnnouncement = async (
  formData: AnnouncementFormData
): Promise<any> => {
  try {
    const response = await attendanceAPI.post(
      `${process.env.REACT_APP_API_URL}/announcment`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Announcement error:", error);
    throw error;
  }
};
export const fetchAllAnnouncement = async () => {
  try {
    const response = await attendanceAPI.get(
      `${process.env.REACT_APP_API_URL}/getAnnouncement`
    );
    return response.data;
  } catch (error) {
    console.error("Announcement error:", error);
    throw error;
  }
};
export const EditAnnouncement = async (Data:any) => {
  try {
    const response = await attendanceAPI.put(
      `${process.env.REACT_APP_API_URL}/Announcement`
    );
    return response.data;
  } catch (error) {
    console.error("Announcement error:", error);
    throw error;
  }
};