// import React, { useState, useEffect } from "react";
// import { Carousel } from "antd";
// import { fetchAllAnnouncement } from "../../../services/announcement/annoucement";
// import { Announcement } from "../../types";
// import { message } from "antd";
// import styled from "styled-components";

// const CarouselComponent: React.FC = () => {
//   const [announcements, setAnnouncements] = useState<Announcement[]>([]);
//   const [announcementsLoading, setAnnouncementsLoading] = useState(true);

//   useEffect(() => {
//     const fetchAnnouncements = async () => {
//       setAnnouncementsLoading(true);
//       try {
//         const response = await fetchAllAnnouncement();
//         if (response.success) {
//           const data: Announcement[] = response.content;
//           const now = new Date();
//           const filteredAnnouncements = data.filter((announcement) => {
//             const startDate = new Date(announcement.startDate);
//             const endDate = new Date(announcement.endDate);
//             return now >= startDate && now <= endDate;
//           });
//           setAnnouncements(filteredAnnouncements);
//         } else {
//           console.error("Error fetching announcements:", response.message);
//           message.error("Failed to load announcements");
//         }
//       } catch (error) {
//         console.error("Error fetching announcements:", error);
//         message.error("Failed to load announcements");
//       } finally {
//         setAnnouncementsLoading(false);
//       }
//     };

//     fetchAnnouncements();
//   }, []);

//   const titleStyle: React.CSSProperties = {
//     fontWeight: "bold",
//     fontSize: "27px",
//     position: "absolute",
//     marginBottom: "130px",
//     textAlign: "center",
//     zIndex: 1,
//   };
//   const contentStyle: React.CSSProperties = {
//     fontSize: "16px",
//     textAlign: "center",
//     lineHeight: "1.5",
//     color: "white",
//     position: "absolute",
//     bottom: "10px",
//     left: "10px",
//     right: "10px",
//   };

//   const contentContainerStyle: React.CSSProperties = {
//     position: "relative",
//     height: "170px",
//     overflow: "hidden",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     color: "white",
//   };
//   const imageStyle: React.CSSProperties = {
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//   };

//   const contentContainerStyle1: React.CSSProperties = {
//     height: "170px",
//     color: "black",
//     background: "rgb(226, 232, 240)",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "10px",
//   };

//   const titleStyle1: React.CSSProperties = {
//     fontWeight: "bold",
//     fontSize: "27px",
//   };

//   const contentStyle1: React.CSSProperties = {
//     fontSize: "16px",
//     textAlign: "center",
//     lineHeight: "1.3",
//     marginBottom: "12px",
//   };
//   const CustomCarousel = styled(Carousel)`
//     .slick-dots li button {
//       background-color: rgb(16, 192, 192);
//     }

//     .slick-dots li.slick-active button {
//       background-color: rgb(16, 192, 192);
//     }
//   `;

//   const carouselItems = announcements.map((announcement, index) => {
//     const images = announcement.imageUrls ?? [];

//     if (images.length > 0) {
//       return {
//         type: "image",
//         content: (
//           <div style={contentContainerStyle}>
//             <img
//               src={images[0]}
//               alt={`Carousel Image ${index}`}
//               style={imageStyle}
//             />
//             <div style={titleStyle}>{announcement.title}</div>
//             <div style={contentStyle}>{announcement.content}</div>
//           </div>
//         ),
//       };
//     }

//     return {
//       type: "text",
//       content: (
//         <div style={contentContainerStyle1}>
//           <div style={titleStyle1}>{announcement.title}</div>
//           <div style={contentStyle1}>{announcement.content}</div>
//         </div>
//       ),
//     };
//   });

//   return (
//     <CustomCarousel autoplay className="mb-6 rounded-xl shadow-lg">
//       {carouselItems.map((item, index) => (
//         <div key={index} style={{ position: "relative", height: "160px" }}>
//           {item.content}
//         </div>
//       ))}
//     </CustomCarousel>
//   );
// };

// export default CarouselComponent;
import React, { useState, useEffect } from "react";
import { fetchAllAnnouncement } from "../../../services/announcement/annoucement";
import { Announcement } from "../../types";
import { message, Spin } from "antd";
import styled, { keyframes, css } from "styled-components";

const scrollLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  background-color: yellow;
`;

const CarouselContent = styled.div<{ hasItems: boolean }>`
  display: inline-block;
  padding-left: 0; // Set default padding
  animation: ${(props) =>
    props.hasItems
      ? css`
          ${scrollLeft} 30s linear infinite
        `
      : "none"};
  white-space: nowrap;
`;

const AnnouncementItem = styled.div`
  display: inline-block;
  padding: 5px 20px;
  font-size: 18px;
  color: #333;
  white-space: nowrap;
`;

const CarouselComponent: React.FC = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [announcementsLoading, setAnnouncementsLoading] = useState(true);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setAnnouncementsLoading(true);
      try {
        const response = await fetchAllAnnouncement();
        if (response.success) {
          const data: Announcement[] = response.content;
          const now = new Date();
          const filteredAnnouncements = data.filter((announcement) => {
            const startDate = new Date(announcement.startDate);
            const endDate = new Date(announcement.endDate);
            return now >= startDate && now <= endDate;
          });
          setAnnouncements(filteredAnnouncements);
        } else {
          console.error("Error fetching announcements:", response.message);
          message.error("Failed to load announcements");
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
        message.error("Failed to load announcements");
      } finally {
        setAnnouncementsLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  if (announcements.length === 0) {
    return null;
  }

  return (
    <CarouselContainer>
      <CarouselContent hasItems={announcements.length > 0}>
        {announcements.map((announcement) => (
          <AnnouncementItem key={announcement.id}>
            <span style={{ fontWeight: "bold" }}>{announcement.title}: </span>
            {announcement.content}
          </AnnouncementItem>
        ))}
      </CarouselContent>
    </CarouselContainer>
  );
};

export default CarouselComponent;
