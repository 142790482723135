import React, { useState, useEffect } from "react";
import { AnnouncementFormData , Announcements } from "../../types";
import {
  createAnnouncement,
  EditAnnouncement,
  fetchAllAnnouncement,
} from "../../../services/announcement/annoucement";
import {
  Table,
  Typography,
  Row,
  Button,
  Col,
  Spin,
  Form,
  notification,
  Modal,
  Input,
  Divider,
} from "antd";
import moment from "moment";
import { format, subDays } from "date-fns";
import { AnnouncementModal } from "./Announcementmodal";
import { EditOutlined } from "@ant-design/icons";
export const Announcement: React.FC = () => {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [annoucement, setannoucement] = useState<any[]>([]);
  const [SelectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementFormData | null>(null);
  const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
  const getData = async () => {
    try {
      const response = await fetchAllAnnouncement();
      if (response && response.content) {
        setannoucement(response.content);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching announcement data:", error);
      setError("Failed to fetching announcement data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  // const handleEdit=async(Announcements)=>{
  //    const Data=Announcements
  //   const response= await EditAnnouncement(Data)
  // }

  const refreshData = async () => {
    setLoading(true);
    try {
      await getData();
    } catch (error) {
      console.error("Error fetching announcements:", error);
      setError("Failed to fetch announcements. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleRowClick = (
    record: AnnouncementFormData,
    columnIndex: number
  ) => {
    if (columns[columnIndex].key !== "Announcment") {
      setSelectedAnnouncement(record);
      setIsReadOnlyModalOpen(true);
    } else {
      setIsReadOnlyModalOpen(false);
    }
  };
  const truncateText = (text: string, wordLimit: number): string => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")}...`;
    }
    return text;
  };

  const columns = [
    {
      title: "Creating Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (date ? format(new Date(date), "PPP") : "N/A"),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string) => (date ? format(new Date(date), "PPP") : "N/A"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => {
        if (!date) return "N/A";
        const adjustedDate = subDays(new Date(date), 1);
        return format(adjustedDate, "PPP");
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text: string) => <span>{truncateText(text, 5)}</span>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: () => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <span style={{ marginRight: 8 }}>
    //         <EditOutlined
    //           style={{ fontSize: 20, cursor: "pointer" }}
    //           onClick={() =>}
              
    //         />
    //       </span>
    //       <span>
    //         <EditOutlined
    //           style={{ fontSize: 20, color: "red", cursor: "pointer" }}
    //           onClick={() => }
              
    //         />
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  const showModal = async () => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      form.resetFields();
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setError("Failed to fetch user data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values: AnnouncementFormData, fileList: any[]) => {
    setLoading(true);
    try {
      const imageUrls = fileList.map((file) => file);

      const announcementData: AnnouncementFormData = {
        ...values,
        imageUrls,
      };
      await createAnnouncement(announcementData);
      setIsModalOpen(false);
      await refreshData();

      notification.success({
        message: "Success",
        description: "The Announcement has been created successfully.😉",
      });
    } catch (error) {
      console.error("Error creating announcement:", error);
      notification.error({
        message: "Error",
        description: "Failed to create the announcement. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {};
  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <div className="flex justify-between bg-slate-200 p-1 rounded-md">
        <div className="border-l-4 border-secondary-color h-9 flex items-center">
          <Title level={5} className="ml-2">
            Announcement
          </Title>
        </div>
        <Button className="mt-0.5" onClick={showModal}>
          <EditOutlined />
          Make Announcement
        </Button>
      </div>
      <AnnouncementModal
        form={form}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        loading={loading}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: 16 }}
      ></Row>
      <Table
        dataSource={annoucement}
        rowKey="_id"
        tableLayout="fixed"
        columns={columns.map((col, index) => ({
          ...col,
          onCell: (record: AnnouncementFormData) => ({
            onClick: () => handleRowClick(record, index),
          }),
        }))}
      />
      <Modal
        title="Announcement"
        visible={isReadOnlyModalOpen}
        onCancel={() => setIsReadOnlyModalOpen(false)}
        footer={null}
        style={{ minWidth: "40vw" }}
        centered
        className="custom-modal"
      >
        <Divider className="!mt-3" />

        {SelectedAnnouncement && (
          <Form
            className="mt-4 w-full"
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="Title">
              <Input value={SelectedAnnouncement.title} readOnly />
            </Form.Item>
            <Form.Item label="Content">
              <Input.TextArea
                rows={4}
                value={SelectedAnnouncement.content}
                readOnly
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item label="Start Date">
                  <Input
                    value={moment(SelectedAnnouncement.startDate).format(
                      "MMMM Do YYYY"
                    )}
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="End Date">
                  <Input
                    value={moment(SelectedAnnouncement.endDate).format(
                      "MMMM Do YYYY"
                    )}
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
};
