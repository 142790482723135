import { AppRoutes } from "./Navigation/AppRoutes";
// import {GlobalStyles} from "./FontFamily";
import "./index.css";

function App() {
  return (
    <div className="app-wrapper">
      {/* <GlobalStyles/> */}
      <AppRoutes />
    </div>
  );
}

export default App;
