import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  message,
  Typography,
  Row,
  Col,
  Modal,
  Divider,
  Upload,
  Card,
  Spin,
  Avatar,
  notification,
  List,
} from "antd";
import moment, { Moment } from "moment";
import attendanceAPI from "../../../services/axios";
import { createUser, updateUser } from "../../../services/userApis/userApis";
import { User, UserData } from "../../types";
import { EditOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import "./AddUserModel.css";
import { AxiosError } from "axios";
import axios from "axios";
import Dropzone, {
  DropzoneState,
  FileRejection,
  DropEvent,
} from "react-dropzone";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DropzoneComponent from "./Dropzone";
import AntDesignDragger from "./Dropzone";

const { Title } = Typography;

type FieldType = {
  fullName?: string;
  fatherName?: string;
  email?: string;
  address?: string;
  phone?: Number;
  companyName?: string;
  department?: string;
  jobType?: string;
  profileImage?: string;
  manager?: string;
  password?: string;
  confirmPassword?: string;
  cnic?: string;
  designation?: string;
  dob?: Date;
  joiningDate?: Date;
  role?: string;
  gender?: string;
  salary?: string;
  userName?: string;
  signInEmail?: string;
  dropZone?: string;
  Skills?: string;
};
const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

interface AddUserModalProps {
  openModal?: boolean;
  closeModal?: () => void;
  userData?: UserData | null;
  onModalClose: () => void;
  fetchUsersData?: () => void;
}
export const AddUserModal: React.FC<AddUserModalProps> = ({
  openModal,
  closeModal,
  userData,
  fetchUsersData,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(openModal ? true : false);
  const [user, setUser] = useState<UserData | null>(userData ? userData : null);
  const [isModalOpen, setIsModalOpen] = useState(openModal ? true : false);
  const [ding, setding] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState<string>("");
  const [cnicValue, setCnicValue] = useState("");
  const showPasswordResetModal = () => setIsPasswordResetModalOpen(true);
  const handlePasswordResetCancel = () => setIsPasswordResetModalOpen(false);
  const handleChange = (value: string) => {
    setSelectedDesignation(value);
  };

  const [documentUrls, setDocumentUrls] = useState<string[]>([]);

  const handleInputClick = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (user && user.userDetail && user.userDetail.profileImage) {
      setProfileImage(user.userDetail.profileImage);
      setDocumentUrls(user.userDetail.document);
    } else {
      setProfileImage("/assets/menIcon.jpg");
    }
  }, [user]);

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    } else {
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsEditing(false);
    form.resetFields();
  };

  const handleCancel = () => {
    if (openModal) {
      closeModal && closeModal();
    } else {
      setIsModalOpen(false);
      setding(false);
      form.resetFields();
      if (fetchUsersData) {
        fetchUsersData();
      }
    }
  };
  interface UserFormProps {
    attendanceAPI: any;
    form: any;
    setIsModalOpen: (isOpen: boolean) => void;
  }

  const handleFileChange = (info: UploadChangeParam) => {
    const newFile = info.file.originFileObj as File | undefined;
    if (newFile) {
      setFile(newFile);
    }
  };

  const handleUploadSuccess = async (info: any) => {
    if (info.file.status === "uploading") {
      setding(true);
    }
    if (info.file.status === "done") {
      const response = info.file.response;
      setImageUrl(response.url);
      setding(false);
    }
  };
  const handleFileUploaded = (fileUrls: string[]) => {
    console.log(fileUrls);
    setDocumentUrls((prevFileUrls) => [...prevFileUrls, ...fileUrls]);
  };
  const handleRemoveDocument = (fileUrl: string) => {
    setDocumentUrls((prevFileUrls) =>
      prevFileUrls.filter((url) => url !== fileUrl)
    );
  };
  const getFileName = (url: any) => {
    return url.substring(url.lastIndexOf("/") + 1);
  };

  const isImageFile = (url: any) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  };
  const onFinish = async (values: any) => {
    setding(true);
    setError(null);
    const payload = {
      userDetail: {
        fullName: values.fullName,
        fatherName: values.fatherName,
        email: values.email,
        address: values.address,
        phone: values.phone,
        dob: values.dob,
        cnic: values.cnic,
        profileImage: imageUrl || profileImage,
        gender: values.gender,
        document: documentUrls,
      },
      jobDetail: {
        companyName: values.companyName,
        department: values.department,
        jobType: values.jobType,
        manager: values.manager,
        designation: values.designation,
        joiningDate: values.joiningDate,
        role: values.role,
        salary: values.salary,
        status: values.status,
        employeeId: values.employeeId,
        dropZone: values.dropZone,
        Skills: values.Skills,
      },
      signInDetail: {
        userName: values.userName,
        signInEmail: values.signInEmail,
        ...(values.password && { password: values.password }),
        ...(values.confirmPassword && {
          confirmPassword: values.confirmPassword,
        }),
      },
    };

    try {
      let response;
      if (openModal && user) {
        response = await attendanceAPI.patch(
          `${process.env.REACT_APP_API_URL}/update?userId=${user._id}`,
          payload
        );
        message.success("User updated successfully");
        if (fetchUsersData) {
          fetchUsersData();
        }
      } else {
        response = await attendanceAPI.post(
          `${process.env.REACT_APP_API_URL}/users`,
          payload
        );
        message.success("User created successfully");
        if (fetchUsersData) {
          fetchUsersData();
        }
      }
      setUser(response.data);
      form.resetFields();
      setIsModalOpen(false);
      if (openModal) {
        closeModal && closeModal();
      }
    } catch (error) {
      console.error("Failed to save user:", error);
      if (error instanceof AxiosError && error.response) {
        const backendError =
          error.response.data?.error ||
          "Failed to save user. Please try again.";
        setError(backendError);
        message.error(backendError);
      } else {
        const genericError = "Failed to save user. Please try again.";
        setError(genericError);
        message.error(genericError);
      }
    } finally {
      setding(false);
    }
  };
  useEffect(() => {
    if (user && isEditing) {
      form.setFieldsValue({
        ...user.userDetail,
        ...user.jobDetail,
        ...user.signInDetail,
        password: "",
        confirmPassword: "",
        dob: user.userDetail.dob
          ? moment(user.userDetail.dob).format("YYYY-MM-DD")
          : null,
        joiningDate: user.jobDetail.joiningDate
          ? moment(user.jobDetail.joiningDate).format("YYYY-MM-DD")
          : null,
      });
    }
  }, [user, form, isEditing]);

  const token = localStorage.getItem("token");

  const handlePasswordReset = async (values: { newPassword: string }) => {
    setLoading(true);

    try {
      const userId = user ? user._id : "";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resetPassword`,
        {
          newPassword: values.newPassword,
        },
        {
          params: { userId },

          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      notification.success({
        message: "Success",
        description: "Password reset successfully.😉",
      });
      form.resetFields();
      handlePasswordResetCancel();
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while resetting the password.",
      });
    } finally {
      setLoading(false);
    }
  };
  type DesignationOption = {
    value: string;
    label: string;
  };

  type AdditionalOptionsType = {
    [key: string]: DesignationOption[];
  };

  const designationOptions: DesignationOption[] = [
    {
      value: "Executive Leadership",
      label: "Executive Leadership",
    },
    {
      value: "Engineering & Development",
      label: "Engineering & Development",
    },
    {
      value: "Product Management",
      label: "Product Management",
    },
    {
      value: "Design & User Experience",
      label: "Design & User Experience",
    },
    {
      value: "Project Management",
      label: "Project Management",
    },
    {
      value: "Data & Analytics",
      label: "Data & Analytics",
    },
    {
      value: "Sales & Marketing",
      label: "Sales & Marketing",
    },
    {
      value: "Customer Support & Success",
      label: "Customer Support & Success",
    },
    {
      value: "Human Resources & Administration",
      label: "Human Resources & Administration",
    },
    {
      value: "Finance & Legal",
      label: "Finance & Legal",
    },
  ];

  const additionalOptions: AdditionalOptionsType = {
    "Executive Leadership": [
      {
        value: "Chief Executive Officer (CEO)",
        label: " Chief Executive Officer (CEO)",
      },
      {
        value: " Chief Technology Officer (CTO)",
        label: "Chief Technology Officer (CTO) ",
      },
      {
        value: " Chief Operating Officer (COO)",
        label: "Chief Operating Officer (COO) ",
      },
      {
        value: "Chief Information Officer (CIO)",
        label: "Chief Information Officer (CIO) ",
      },
      {
        value: "Chief Product Officer (CPO) ",
        label: " Chief Product Officer (CPO)",
      },
      {
        value: " Chief Financial Officer (CFO)",
        label: "Chief Financial Officer (CFO) ",
      },
      {
        value: "Chief Marketing Officer (CMO)",
        label: "Chief Marketing Officer (CMO)",
      },
      {
        value: " Chief Data Officer (CDO)",
        label: " Chief Data Officer (CDO)",
      },
    ],
    "Engineering & Development": [
      {
        value: " Vice President of Engineering",
        label: "Vice President of Engineering ",
      },
      { value: "Engineering Manager", label: " Engineering Manager" },
      { value: " Director of Engineering", label: "Director of Engineering " },
      { value: "Lead Software Engineer ", label: "Lead Software Engineer " },
      {
        value: "Principal Software Engineer ",
        label: "Principal Software Engineer ",
      },
      { value: "Senior Software Engineer", label: "Senior Software Engineer " },
      { value: "Software Engineer ", label: "Software Engineer " },
      {
        value: "Junior Software Engineer ",
        label: "Junior Software Engineer ",
      },
      {
        value: "Software Development Manager ",
        label: " Software Development Manager",
      },
      { value: "Full Stack Developer", label: " Full Stack Developer" },
      { value: " Frontend Developer", label: "Frontend Developer " },
      { value: "Backend Developer ", label: " Backend Developer" },
      { value: "Mobile Developer ", label: " Mobile Developer" },
      { value: "DevOps Engineer ", label: "DevOps Engineer " },
      {
        value: " Site Reliability Engineer (SRE)",
        label: " Site Reliability Engineer (SRE)",
      },
      { value: "Systems Architect", label: "Systems Architect " },
      { value: " Application Architect", label: "Application Architect " },
      { value: "Technical Lead ", label: " Technical Lead" },
      {
        value: " Embedded Software Engineer",
        label: "Embedded Software Engineer ",
      },
      {
        value: "Quality Assurance Engineer (QA) ",
        label: " Quality Assurance Engineer (QA)",
      },
      {
        value: " Test Automation Engineer",
        label: " Test Automation Engineer",
      },
      { value: "Security Engineer", label: "Security Engineer " },
      { value: " Data Engineer", label: "Data Engineer " },
      { value: "AI/ML Engineer ", label: "AI/ML Engineer " },
      { value: " Cloud Engineer", label: "Cloud Engineer " },
    ],
    "Product Management": [
      {
        value: "Vice President of Product Management",
        label: " Vice President of Product Management ",
      },
      {
        value: "Director of Product Management  ",
        label: " Director of Product Management",
      },
      { value: "Senior Product Manager", label: "Senior Product Manager" },
      { value: "Product Manager", label: " Product Manager " },
      {
        value: " Associate Product Manager ",
        label: "Associate Product Manager ",
      },
      {
        value: "Technical Product Manager",
        label: "Technical Product Manager",
      },
      { value: "Product Owner", label: " Product Owner " },
      { value: " Business Analyst ", label: "Business Analyst " },
    ],

    "Design & User Experience": [
      { value: "Vice President of Design", label: "Vice President of Design " },
      { value: " Director of UX/UI ", label: "Director of UX/UI " },
      { value: "UX/UI Designer", label: "UX/UI Designer" },
      { value: "Senior UX/UI Designer", label: "Senior UX/UI Designer  " },
      { value: " Interaction Designer ", label: "Interaction Designer " },
      { value: "Visual Designer", label: "Visual Designer" },
      { value: "Graphic Designer", label: " Graphic Designer " },
      {
        value: "  User Experience Researcher (UX Researcher)",
        label: "User Experience Researcher (UX Researcher) ",
      },
      { value: "Product Designer", label: "Product Designer" },
    ],

    "Project Management": [
      {
        value: "Vice President of Project Management",
        label: " Vice President of Project Management ",
      },
      {
        value: "Director of Project Management  ",
        label: " Director of Project Management",
      },
      { value: "Program Manager", label: "Program Manager" },
      { value: "Senior Project Manager", label: " Senior Project Manager " },
      { value: " Project Manager ", label: "Project Manager " },
      { value: "Scrum Master", label: "Scrum Master" },
      { value: "Agile Coach", label: " Agile Coach " },
      {
        value: " Technical Program Manager ",
        label: " Technical Program Manager",
      },
    ],
    "Data & Analytics": [
      {
        value: "Vice President of Data Science",
        label: " Vice President of Data Science ",
      },
      {
        value: " Director of Data Science ",
        label: " Director of Data Science",
      },
      { value: "Data Scientist", label: "Data Scientist" },
      { value: "Senior Data Scientist", label: " Senior Data Scientist " },
      { value: " Data Analyst ", label: "Data Analyst " },
      { value: "Data Engineer", label: "Data Engineer" },
      {
        value: "Machine Learning Engineer",
        label: "Machine Learning Engineer  ",
      },
      {
        value: " Business Intelligence Analyst ",
        label: "Business Intelligence Analyst ",
      },
      { value: "Data Architect", label: "Data Architect" },
    ],
    "Sales & Marketing": [
      { value: "Vice President of Sales", label: "Vice President of Sales  " },
      { value: " Director of Sales ", label: "Director of Sales " },
      { value: "Sales Manager", label: "Sales Manager" },
      { value: "Account Executive", label: " Account Executive " },
      { value: " Sales Engineer ", label: "Sales Engineer " },
      {
        value: "Business Development Manager",
        label: "Business Development Manager",
      },
      {
        value: "Vice President of Marketing",
        label: "Vice President of Marketing  ",
      },
      { value: " Director of Marketing ", label: "Director of Marketing " },
      { value: "Marketing Manager", label: "Marketing Manager" },
      {
        value: "Digital Marketing Specialist",
        label: " Digital Marketing Specialist ",
      },
      {
        value: "Content Marketing Specialist  ",
        label: "Content Marketing Specialist ",
      },
    ],
    "Customer Support & Success": [
      {
        value: "Vice President of Customer Success",
        label: " Vice President of Customer Success ",
      },
      {
        value: " Director of Customer Success ",
        label: "Director of Customer Success ",
      },
      { value: "Customer Success Manager", label: "Customer Success Manager" },
      {
        value: "Customer Support Manager",
        label: " Customer Support Manager ",
      },
      {
        value: " Technical Support Engineer ",
        label: "Technical Support Engineer ",
      },
      { value: "Help Desk Support", label: "Help Desk Support" },
      {
        value: "Client Relationship Manager",
        label: "Client Relationship Manager  ",
      },
    ],
    "Human Resources & Administration": [
      {
        value: "Vice President of Human Resources",
        label: " Vice President of Human Resources ",
      },
      {
        value: " Director of Human Resources ",
        label: " Director of Human Resources",
      },
      { value: "HR Manager", label: "HR Manager" },
      {
        value: "Talent Acquisition Specialist",
        label: " Talent Acquisition Specialist ",
      },
      { value: " HR Business Partner ", label: "HR Business Partner " },
      { value: "Office Manager", label: "Office Manager" },
      {
        value: "Administrative Assistant",
        label: " Administrative Assistant ",
      },
    ],

    "Finance & Legal": [
      {
        value: "Vice President of Finance  ",
        label: " Vice President of Finance",
      },
      { value: "Director of Finance", label: "Director of Finance" },
      { value: "Controller", label: "Controller  " },
      { value: " Finance Manager ", label: " Finance Manager" },
      { value: "Financial Analyst", label: "Financial Analyst" },
      { value: " Legal Counsel ", label: "Legal Counsel " },
      { value: "Compliance Officer", label: "Compliance Officer" },
    ],
  };
  const formatCNIC = (value: string): string => {
    value = value.replace(/\D/g, "");

    if (value.length <= 5) {
      return value;
    } else if (value.length <= 12) {
      return `${value.slice(0, 5)}-${value.slice(5)}`;
    } else {
      return `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(
        12,
        13
      )}`;
    }
  };
  const handleCnicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCNIC(e.target.value);
    setCnicValue(formattedValue);
    form.setFieldsValue({ cnic: formattedValue });
  };
  return (
    <div>
      {!openModal && (
        <div className="flex justify-between bg-slate-200 p-1 rounded-md">
          <div className="border-l-4 r h-9 flex items-center">
            <Title level={5} className="ml-2"></Title>
          </div>
          <Button onClick={showModal}>
            <EditOutlined />
            New Employee
          </Button>
        </div>
      )}
      <Modal
        title={isEditing ? "Edit Employee" : "New Employee"}
        visible={isModalOpen}
        onCancel={handleCancel}
        className="!w-[70%]"
        confirmLoading={ding}
        footer={[
          <div className="footerContainer">
            <Button
              key="cancel"
              onClick={handleCancel}
              className="footerButton"
            >
              Cancel
            </Button>
            {isEditing && (
              <Button
                key="resetPassword"
                type="default"
                onClick={showPasswordResetModal}
                className="footerButton"
              >
                Reset Password
              </Button>
            )}
            <Button
              key="submit"
              type="primary"
              onClick={() => form.submit()}
              // ding={ding}
              className="footerButton"
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Divider className="!mt-3" />

        <Row gutter={[16, 0]}>
          <Col xs={8} lg={6} style={{ marginTop: 20 }}>
            <Form.Item name="profileImage">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={imageUrl || profileImage}
                  alt="User Profile"
                  style={{
                    width: 140,
                    height: 140,
                    marginTop: 10,
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <Upload
                  name="file"
                  action={`${process.env.REACT_APP_API_URL}/upload`}
                  method="post"
                  accept="image/*"
                  showUploadList={false}
                  onChange={handleUploadSuccess}
                  beforeUpload={(file) => true}
                >
                  <Spin
                    spinning={ding}
                    indicator={<Spin style={{ fontSize: 24 }} />}
                    size="large"
                  />
                  <Button
                    icon={<UploadOutlined style={{ padding: "5px" }} />}
                    type="primary"
                    htmlType="submit"
                    className="w-full !bg-secondary-color"
                    style={{ display: ding ? "none" : "block" }}
                  >
                    Upload Profile Image
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </Col>
          <Col xs={8} sm={2} md={16} lg={18}>
            <div
              className="modalBody"
              style={{
                maxHeight: "60vh",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              <div className="form-container">
                <Form
                  form={form}
                  className="mt-4 w-full"
                  name="basic"
                  labelCol={{ span: 8 }}
                  initialValues={{
                    remember: true,
                    password: isEditing ? "" : "AccellionX@123",
                    confirmPassword: isEditing ? "" : "AccellionX@123",
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}></Col>
                  <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                    <Title level={5} className="ml-2">
                      User Details
                    </Title>
                  </div>

                  <Row gutter={[16, 0]}>
                    {" "}
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Full Name
                          </span>
                        }
                        name="fullName"
                      >
                        <Input placeholder="Enter full name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Father Name" name="fatherName">
                        <Input placeholder="Enter father name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Email
                          </span>
                        }
                        name="email"
                      >
                        <Input type="email" placeholder="Enter email" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Address" name="address">
                        <Input placeholder="Enter address" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Phone Number
                          </span>
                        }
                        labelCol={{ span: 10 }}
                        name="phone"
                      >
                        <PhoneInput
                          country="pk"
                          inputStyle={{ width: "100%", height: "32px" }}
                          containerStyle={{ width: "100%", height: "32px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>CNIC
                          </span>
                        }
                        name="cnic"
                      >
                        <Input
                          placeholder="XXXXX-XXXXXXX-X"
                          value={cnicValue}
                          onChange={handleCnicChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Gender
                          </span>
                        }
                        labelCol={{ span: 12 }}
                        name="gender"
                      >
                        <Select
                          defaultValue="Select Gender"
                          onChange={handleChange}
                          options={[
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> DOB
                          </span>
                        }
                        name="dob"
                      >
                        {user && openModal ? (
                          <Input
                            defaultValue={moment(user.userDetail.dob).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        ) : (
                          <DatePicker style={{ width: "100%" }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                    <Title level={5} className="ml-2">
                      Jobs Details
                    </Title>
                  </div>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Company Name"
                        labelCol={{ span: 10 }}
                        name="companyName"
                      >
                        <Input placeholder="Enter company name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>Job Type
                          </span>
                        }
                        name="jobType"
                      >
                        <Select
                          defaultValue="Job Type"
                          onChange={handleChange}
                          options={[
                            { value: "Full Time", label: "Full Time" },
                            { value: "Part Time", label: "Part Time" },
                            { value: "Remote", label: "Remote" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Department" name="department">
                        <Select
                          defaultValue="Select Designation"
                          onChange={handleChange}
                          options={designationOptions}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Designation"
                        labelCol={{ span: 12 }}
                        name="designation"
                      >
                        <Select
                          defaultValue="Select Designation"
                          onChange={handleChange}
                          options={
                            additionalOptions[
                              selectedDesignation as keyof AdditionalOptionsType
                            ]
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Manager" name="manager">
                        <Select
                          defaultValue="Select Manager"
                          onChange={handleChange}
                          options={[
                            { value: "Ali", label: "Ali" },
                            { value: "Aqib", label: "Aqib" },
                            {
                              value: "Muhammad Musharaf",
                              label: "Muhammad Musharaf",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span> Select role
                          </span>
                        }
                        name="role"
                      >
                        <Select
                          defaultValue="Select role"
                          onChange={handleChange}
                          options={[
                            { value: "admin", label: "Admin" },
                            { value: "user", label: "User" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Salary" name="salary">
                        <Input placeholder="Enter Salary " />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>Joining Date
                          </span>
                        }
                        name="joiningDate"
                      >
                        {user && openModal ? (
                          <Input
                            defaultValue={moment(
                              user.jobDetail.joiningDate
                            ).format()}
                          />
                        ) : (
                          <DatePicker style={{ width: "100%" }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}></Col>
                  </Row>
                  <div className="border-l-4 border-secondary-color h-7 flex items-center mb-1">
                    <Title level={5} className="ml-2">
                      SignIn Details
                    </Title>
                  </div>
                  <Row gutter={[16, 0]}>
                    {" "}
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name="userName"
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>User Name
                          </span>
                        }
                      >
                        <Input placeholder="Enter full name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label={
                          <span style={{ color: "black" }}>
                            <span style={{ color: "red" }}>*</span>SignIn Email
                          </span>
                        }
                        name="signInEmail"
                      >
                        <Input type="email" placeholder="Enter SignIn Email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    {!isEditing && (
                      <Col xs={24} sm={12}>
                        <Form.Item
                          label={
                            <span style={{ color: "black" }}>
                              <span style={{ color: "red" }}>*</span>Password
                            </span>
                          }
                          name="password"
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                    )}
                    {!isEditing && (
                      <Col xs={24} sm={12}>
                        <Form.Item
                          label={
                            <span style={{ color: "black" }}>
                              <span style={{ color: "red" }}>*</span>Confirm
                              Password
                            </span>
                          }
                          labelCol={{ span: 10 }}
                          name="confirmPassword"
                        >
                          <Input.Password
                            placeholder={
                              isEditing
                                ? "Leave blank to keep unchanged"
                                : "Confirm password"
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <div className="">
                    <div className="border-l-4 border-secondary-color h-7 items-center mb-4">
                      <Title level={5} className="ml-2">
                        Employee Documents
                      </Title>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <List
                        bordered
                        dataSource={documentUrls}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Button
                                type="link"
                                danger
                                onClick={() => handleRemoveDocument(item)}
                              >
                                Remove
                              </Button>,
                            ]}
                          >
                            {isImageFile(item) ? (
                              <img
                                src={item}
                                alt={getFileName(item)}
                                style={{
                                  width: "170px",
                                  height: 100,
                                  objectFit: "cover",
                                  marginRight: 10,
                                }}
                              />
                            ) : (
                              <span>{getFileName(item)}</span>
                            )}
                          </List.Item>
                        )}
                      />
                    </div>
                    <br />
                    <div>
                      <AntDesignDragger
                        uploadUrl={`${process.env.REACT_APP_API_URL}/documentUpload`}
                        maxFilesize={5}
                        onDrop={handleFileUploaded}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Reset Password"
        visible={isPasswordResetModalOpen}
        onCancel={handlePasswordResetCancel}
        footer={null}
        className="!w-[50%]"
      >
        <Form
          form={form}
          name="resetPassword"
          onFinish={handlePasswordReset}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please input the new password!" },
              {
                min: 8,
                message: "Password must be at least 8 characters long!",
              },
            ]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              { required: true, message: "Please confirm the new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm new password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
