import {
  Typography,
  DatePicker,
  Divider,
  Row,
  Card,
  message,
  Avatar,
  Modal,
  Table,
  Layout,
  Progress,
  Col,
  Spin,
} from "antd";
import { FaRegStopCircle, FaThLarge } from "react-icons/fa";
import { FaRegPlayCircle } from "react-icons/fa";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { LineChart } from "@mui/x-charts/LineChart";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { CloseOutlined } from "@ant-design/icons";
import { BsPersonWorkspace } from "react-icons/bs";

import {
  CheckInApi,
  CheckOutApi,
  fetchUserAttendance,
} from "../../../services/attendenceApis/attendence";
import { AttendanceRecord, UserData, AttendanceData } from "../../types";
import { fetchUserData } from "../../../services/userApis/userApis";
import { fetchAllLeaveApplicationsUser } from "../../../services/leaveApplication/leaveApplication";
const { Title } = Typography;
export const DashContent = () => {
  const [hourCard, setHourCard] = useState(false);
  const [action, setAction] = useState<number>(0);
  const [attendanceData, setAttendanceData] = useState<AttendanceRecord | null>(
    null
  );
  const [Userattendance, setUserttendance] = useState<AttendanceData[]>([]);
  const [Todayattendance, setTodayAttendance] = useState<AttendanceData[]>([]);
  const [todayData, setTodayData] = useState([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [checkIn, setCheckIn] = useState(false);
  const [workingHours, setWorkingHours] = useState("");
  const [visible, setVisible] = useState(false);
  const showimageModal = () => setVisible(true);
  const handleimageCancel = () => setVisible(false);
  const [leaveApplicationData, setLeaveApplicationData] = useState([]);
  const [approved, setApproved] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);

      try {
        const response = await fetchUserAttendance();
        setUserttendance(response.data);
        setTodayAttendance(response.data);
        if (response.data[response.data.length - 1].time_in) {
          setCheckIn(true);
        }

        const userDetail = await fetchUserData();
        setUserData(userDetail);

        const latestRecord = response.data[response.data.length - 1];
        const todayDate = new Date().toISOString().substring(0, 10);

        if (Array.isArray(response.data) && response.data.length > 0) {
          const hasCheckedInToday =
            latestRecord?.time_in &&
            latestRecord?.time_in.toLocaleString().slice(0, 10) === todayDate;
          const hasCheckedOut = latestRecord?.time_out;

          setCheckIn(hasCheckedInToday);
          setHourCard(hasCheckedInToday && !hasCheckedOut);
          setShowTable(hasCheckedInToday && hasCheckedOut);

          if (hasCheckedInToday) {
            setAttendanceData(latestRecord);
            const filteredTodayData = response.data.filter((item: any) => {
              const itemDate = new Date(item.date).toISOString().split("T")[0];
              return itemDate === todayDate;
            });
            setTodayData(filteredTodayData);
            setLoading(false);
          }
        } else {
          setCheckIn(false);
          setHourCard(false);
          setShowTable(false);
        }
      } catch (error) {
        console.error("Error fetching user attendance:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [action]);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchAllLeaveApplicationsUser();
        if (response && response.content) {
          setLeaveApplicationData(response.content);
          const approvedLeaveApplications = response.content.filter(
            (item: { leaveStatus: string }) => item.leaveStatus === "Approved"
          );
          setApproved(approvedLeaveApplications);
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        console.error("Error fetching leave applications data:", error);
        setError("Failed to fetch leave applications. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleCheckIn = async () => {
    try {
      const responseData = await CheckInApi();
      if (responseData.checkedIn) {
        message.success("You Checked In successfully");
        setCheckIn(true);
        setHourCard(true);
        setShowTable(false);
        setAction((prev) => prev + 1);
      } else {
        message.error("You already checked in");
      }
    } catch (error) {
      console.log("Error checking in", error);
    }
  };

  const handleCheckOut = async (confirm: boolean) => {
    try {
      if (!hourCard) {
        message.error("You are already checked out");
        return;
      }
      function timeStringToMilliseconds(timeString: string): number {
        const [hours, minutes, seconds] = timeString.split(":").map(Number);
        return (hours * 3600 + minutes * 60 + seconds) * 1000;
      }

      const timeString: string = calculateWorkingHours();
      const timeInMilliseconds: number = timeStringToMilliseconds(timeString);

      if (timeInMilliseconds < 18000000 && !confirm) {
        const mess = (
          <span>
            Your Working Hour's is
            <strong style={{ color: "red" }}> {calculateWorkingHours()}</strong>
            . You will be marked as
            <strong style={{ color: "red" }}> Absent</strong>. Do you want to
            continue?
          </span>
        );
        Modal.confirm({
          title: "Are You Sure?",
          content: mess,
          centered: true,
          onOk: async () => {
            try {
              const confirmData = await CheckOutApi(true);
              message.success(confirmData.message);
              setAction((prev) => prev + 1);
              setHourCard(false);
              setShowTable(true);
            } catch (error) {
              console.error("Error during confirmed check-out:", error);
              message.error("Failed to record check-out");
            }
          },
          onCancel: () => {
            message.info("Check-out cancelled");
          },
        });
      } else if (
        timeInMilliseconds >= 18000000 &&
        timeInMilliseconds <= 21600000 &&
        !confirm
      ) {
        const mess2 = (
          <span>
            Your Working Hours is
            <strong> {calculateWorkingHours()}</strong>. You will be marked as
            <strong style={{ color: "blue" }}> Half Day</strong>. Do you want to
            continue?
          </span>
        );
        Modal.confirm({
          title: "Are You Sure?",
          content: mess2,
          centered: true,
          onOk: async () => {
            try {
              const confirmData = await CheckOutApi(true);
              message.success(confirmData.message);
              setAction((prev) => prev + 1);
              setHourCard(false);
              setShowTable(true);
            } catch (error) {
              console.error("Error during confirmed check-out:", error);
              message.error("Failed to record check-out");
            }
          },
          onCancel: () => {
            message.info("Check-out cancelled");
          },
        });
      } else {
        const mess3 = (
          <span>
            'After checking out, you cannot{" "}
            <strong style={{ color: "black" }}> Check In </strong> again until
            tomorrow.'Do you want to continue?
          </span>
        );
        Modal.confirm({
          title: "Are You Sure?",
          content: mess3,
          centered: true,
          onOk: async () => {
            try {
              const confirmData = await CheckOutApi(true);
              message.success(confirmData.message);
              setAction((prev) => prev + 1);
              setHourCard(false);
              setShowTable(true);
            } catch (error) {
              console.error("Error during confirmed check-out:", error);
              message.error("Failed to record check-out");
            }
          },
          onCancel: () => {
            message.info("Check-out cancelled");
          },
        });
      }
    } catch (error) {
      console.error("Error during initial check-out:", error);
      message.error("Failed to check out");
    }
  };

  const handleClick = async (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    await handleCheckOut(false);
  };
  const convertToHHMMSS = (decimalHours: number) => {
    if (isNaN(decimalHours)) {
      return "N/A";
    }
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  const IconvertToHHMMSS = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const calculateWorkingHours = (): string => {
    if (!attendanceData || !attendanceData.time_in) return "0";
    const currentTime = new Date();
    const timeDifference =
      currentTime.getTime() - new Date(attendanceData.time_in).getTime();
    const workingHoursInSeconds = timeDifference / 1000;
    return IconvertToHHMMSS(workingHoursInSeconds);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const newWorkingHours = calculateWorkingHours();
      setWorkingHours(newWorkingHours);
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateWorkingHours]);
  const getStatusColor = (status: string): string => {
    switch (status) {
      case "Present":
        return "green";
      case "Absent":
        return "red";
      case "Half Day":
        return "Blue";
      case "Leave":
        return "orange";
      default:
        return "gray";
    }
  };
  const columns = [
    {
      title: "Checked In",
      dataIndex: "time_in",
      key: "time_in",
      render: (time_in: string) => format(new Date(time_in), "hh:mm a"),
    },
    {
      title: "Checked Out",
      dataIndex: "time_out",
      key: "time_out",
      render: (time_out: string) => {
        if (!time_out || isNaN(Date.parse(time_out))) {
          return "N/A";
        }
        return format(new Date(time_out), "hh:mm a");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span
          style={{
            backgroundColor: getStatusColor(status),
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline-block",
          }}
        >
          {status}
        </span>
      ),
    },
    {
      title: "Working Hours",
      dataIndex: "working_hours",
      key: "working_hours",
      render: (working_hours: number) => convertToHHMMSS(working_hours),
    },
  ];

  const workingDays = Userattendance.length;
  const PerWorkDay = Math.trunc((workingDays / workingDays) * 100) || 0;
  const PerPre =
    Math.trunc(
      (Userattendance?.filter((item) => item.status === "Present").length /
        workingDays) *
        100
    ) || 0;
  const PerAbse =
    Math.trunc(
      (Userattendance?.filter((item) => item.status === "Absent").length /
        workingDays) *
        100
    ) || 0;
  const PerLeave = Math.trunc((approved.length / workingDays) * 100) || 0;

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <div className="bg-slate-200 p-1 rounded-md">
        <div className="border-l-4 border-secondary-color flex justify-between h-15 mr-10">
          <div>
            <Title level={5} className="ml-2 mt-4">
              Good Day,{" "}
              <span style={{ fontWeight: "bold" }}>
                {userData?.userDetail.fullName}
              </span>
            </Title>
            <Typography className="text-slate-600 ml-2 ">
              {new Date().toLocaleDateString()}
            </Typography>
          </div>
          <div className="flex items-center ">
            <Avatar
              style={{ width: "65px", height: "65px", cursor: "pointer" }}
              className="hover:scale-110 transition duration-150"
              onClick={showimageModal}
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={userData?.userDetail.profileImage}
                alt="User Avatar"
              />
            </Avatar>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Layout className="bg-white flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-5 px-4 md:px-5">
            <div className="col-span-1">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition-transform duration-150">
                <div className="flex justify-between items-center px-4 md:px-5 py-2">
                  <div>
                    <BsPersonWorkspace size={32} />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerWorkDay}
                      strokeWidth={10}
                      strokeColor="rgb(2,132,199)"
                      trailColor="red"
                      format={() => <span>{`${PerWorkDay}%`}</span>}
                      size={60}
                    />
                  </div>
                </div>
                <div className="px-4 md:pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Working Day's
                  </div>
                  <div className="text-3xl font-semibold">
                    {workingDays || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition-transform duration-150">
                <div className="flex justify-between items-center px-4 md:px-5 py-2">
                  <div>
                    {" "}
                    <PersonAddIcon fontSize="large" />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerPre}
                      strokeWidth={10}
                      strokeColor="rgb(46, 150, 255)"
                      trailColor="rgb(2, 178, 175)"
                      format={() => <span>{`${PerPre}%`}</span>}
                      size={60}
                    />
                  </div>
                </div>
                <div className="px-4 md:pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Total Present's
                  </div>
                  <div className="text-3xl font-semibold">
                    {Userattendance?.filter((item) => item.status === "Present")
                      .length || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition-transform duration-150">
                <div className="flex justify-between items-center px-4 md:px-5 py-2">
                  <div>
                    <PersonRemoveIcon fontSize="large" />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerAbse}
                      strokeWidth={10}
                      strokeColor="rgb(46, 150, 255)"
                      trailColor="rgb(2, 178, 175)"
                      format={() => <span>{`${PerAbse}%`}</span>}
                      size={60}
                    />
                  </div>
                </div>
                <div className="px-4 md:pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Total Absent's
                  </div>
                  <div className="text-3xl font-semibold">
                    {Userattendance?.filter((item) => item.status === "Absent")
                      .length || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition-transform duration-150">
                <div className="flex justify-between items-center px-4 md:px-5 py-2">
                  <div>
                    {" "}
                    <ContactMailIcon fontSize="large" />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerLeave}
                      strokeWidth={10}
                      strokeColor="rgb(2,132,199)"
                      trailColor="rgb(29, 198, 128)"
                      format={() => <span>{`${PerLeave}%`}</span>}
                      size={60}
                    />
                  </div>
                </div>
                <div className="px-4 md:pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Total Leave's
                  </div>
                  <div className="text-3xl font-semibold">
                    {approved.length}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
      <div className="mt-7 mb-12">
        <Row className=" flex justify-center">
          {!checkIn && (
            <Card
              className="mr-4 bg-slate-200 shadow-lg hover:scale-105 transition duration-150"
              onClick={handleCheckIn}
            >
              <Title level={5}>
                {attendanceData?.time_in
                  ? attendanceData?.time_in.toLocaleString().slice(11, 19)
                  : "Check In Now!"}
              </Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaRegPlayCircle
                  style={{
                    fontSize: "40px",
                    cursor: "pointer",
                    color: "#1E5128",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15px",
                  marginTop: "10px",
                }}
              >
                <span>Checked In</span>
              </div>
            </Card>
          )}
          {hourCard ? (
            <Card
              className="mr-4 bg-slate-200 shadow-lg"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <title>
                {attendanceData?.time_in
                  ? attendanceData?.time_in.toLocaleString().slice(11, 19)
                  : "Check Out after CheckIn"}
              </title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={5}>
                  {attendanceData?.time_in
                    ? new Date(attendanceData?.time_in).toLocaleString(
                        "en-US",
                        {
                          timeZone: "Asia/Karachi",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )
                    : "N/A"}
                </Title>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15px",
                  marginTop: "10px",
                  width: "100px",
                }}
              >
                <strong>Check In</strong>
              </div>
            </Card>
          ) : (
            ""
          )}
          {attendanceData?.time_in && hourCard ? (
            <Card className="mr-4 bg-orange-200 shadow-lg hover:scale-105 transition duration-150">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={5}>
                  {attendanceData?.time_out
                    ? new Date(attendanceData?.time_out).toLocaleString(
                        "en-US",
                        {
                          timeZone: "Asia/Karachi",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )
                    : "0"}
                </Title>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaRegStopCircle
                  onClick={handleClick}
                  style={{ fontSize: "40px", cursor: "pointer", color: "red" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15px",
                  marginTop: "10px",
                }}
              >
                <strong>Checked Out</strong>
              </div>
            </Card>
          ) : (
            ""
          )}
          {hourCard ? (
            <Card
              className="mr-4 bg-slate-200 shadow-lg"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title level={5}>
                  {attendanceData?.working_hours
                    ? convertToHHMMSS(Number(attendanceData?.working_hours))
                    : calculateWorkingHours()}
                </Title>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15px",
                  marginTop: "10px",
                }}
              >
                <span>Working hours</span>
              </div>
            </Card>
          ) : (
            ""
          )}

          {showTable && (
            <div className="mt-4 shadow-lg  hover:scale-105 transition duration-150">
              <div className="flex justify-between bg-slate-200 p-1 rounded-md mt-4">
                <div className=" border-secondary-color h-9 flex items-center">
                  <Title level={5} className="ml-2">
                    Today Attendance
                  </Title>
                </div>
              </div>
              <Table
                dataSource={todayData}
                columns={columns}
                bordered
                pagination={false}
              />
            </div>
          )}
        </Row>
      </div>
      <Modal
        visible={visible}
        onCancel={handleimageCancel}
        footer={null}
        width={400}
        style={{ top: "40%", transform: "translateY(-50%)" }}
        closeIcon={<CloseOutlined />}
      >
        <div className="modal-content" style={{ textAlign: "center" }}>
          <img
            src={userData?.userDetail.profileImage}
            alt="User Avatar"
            style={{
              width: "300px",
              height: "300px",
              objectFit: "cover",
              display: "block",
              margin: "0 auto",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
